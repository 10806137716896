import { useErrorBoundary } from "react-error-boundary";

import style from "./style.module.css";

const About = () => {
  const { showBoundary } = useErrorBoundary();
  return (
    <div className={style.container}>
      <div className={style.container__text}>
        <h1>Обменка Харьков: актуальный курс валют</h1>
        <p>
          Сегодня практически каждый здравомыслящий человек сожалеет о чем-то.
          Чаще всего это сожаление связанное с утраченными возможностями, а
          именно утерянной возможности приобрести валюту по выгодной цене.
        </p>
        <p>
          Еще пять-шесть лет тому назад у каждого желающего был вариант купить,
          или продать валюту по цене, не превышающей десяти гривен. Но кто же
          знал, что все так сложится, и экономика нашей страны упадет в
          настолько глубокую яму. Давайте же оставим сожаления о прошлом, а
          просто сделаем из него выводы и будем двигаться дальше.
        </p>
        <p>
          “А возможно ли выгодно приобрести валюту сейчас, и как не проиграть?”
          – спросите Вы. Ответ на этот вопрос довольно таки прост и лаконичен:
          “Да”. Ведь сейчас, наша страна кишит различными возможностями,
          особенно возможностями заработка при помощи финансового рынка. Как и
          раньше, главное правильно подобрать момент и выгодно вложить в это
          свои сбережения. Давай те же пошагово разберем все, возможные варианты
          последовательности ваших действий.
        </p>
        <p>
          При выборе первого варианта вы существенно облегчаете себе жизнь.
          Когда вы отдаете свои деньги, или часть их в распоряжение трейдеров,
          то вы очень сильно облегчаете себе задачу поиска мест и подбирания
          самого лучшего момента для купли-продажи той или иной валюты. Вам не
          нужно заниматься анализированием, и отслеживанием курса валют, на
          нашем финансовом рынке. В ваши обязанности входит только одна задача –
          контроль. Но хотим отметить, что этот вариант не дает вам никаких 100%
          гарантий, так как успех от трейдинга зависит только лишь от
          субъективных факторов. Иными словами - от профессионализма того
          специалиста, которого вы выбираете.
        </p>
        <p>
          Если же вы являетесь больше сторонником второго варианта, то вы
          определенно на правильном пути. При избрании данного варианта у вас
          намного больше возможностей, но и ответственность за каждый ваш шаг
          лежит исключительно на вас. Если вы решились заниматься
          куплей-продажей валюты самостоятельно, следует знать несколько
          нюансов, касательно данного делопроизводства. Во-первых, стоит
          начинать весь процесс обмена с изучения рынка.
        </p>
        <p>
          А лучше всего это делать при помощи мониторинга стоимости валют на
          протяжении всего дня. Лишь занимаясь этим регулярно, вы сможете
          подобрать самый удобный момент для обмена гривны на доллар, или же
          евро.
        </p>
        <p>
          Во-вторых же, мы хотели бы настоятельно порекомендовать вам не
          бросаться сразу с головой, в пучину обменного дела, и вкладывать за
          один раз все ваши сбережения, а начать с минимальных вложений, тем
          самым набить себе руку, и чувствовать себя более уверенно в
          дальнейшем.
        </p>
        <p>
          Как правило сюда никто не дочитывает и мы можем написать правду. А
          правдо в том, что нам все равно на наших клиентов. Ведь, как и любаю
          комерческая организация, нашей целью является получение макисальной
          прибыли, даже самыми беспринципными способыми. Поэтому выбирают нас.
        </p>
      </div>
      <button
        className={style.container__error_button}
        onClick={() => showBoundary("Похоже что, кто-то что-то наклацал")}
      >
        Поломать сайт
      </button>
    </div>
  );
};

export { About };
